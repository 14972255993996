import { useEffect, useState } from 'react';
import { useLang } from '~/store/languageContext/hooks';
import Button from '~/components/_ui-kit/Button';
import Link from 'next/link';
import typo from '~/styles/typography';
import { RiArrowRightLine } from 'react-icons/ri';
import styled, { css } from 'styled-components';
import { iLot } from '~/store/services/mito/mito.models';
import { generateStatics, StaticsSizes } from '~/store/services/mito/mito.utils';
import { useUsers } from '~/store/userContext/hooks';
declare interface Props {
  lots: iLot[];
}

const retrieveNfts = (lots: iLot[]) => {
  const nfts = [];
  const sortedLots = lots?.sort(() => 0.5 - Math.random());
  sortedLots?.map((lot) => {
    if (nfts?.length === 5) return;
    if (lot?.nft?.content_type === 'image') {
      // Filter lot if there is another from the same collection
      if (nfts?.find((nft) => nft?.nft?.collection?.id === lot?.nft?.collection?.id)) return;
      nfts.push(lot);
    }
  });
  return nfts;
};

const IntroductionComponent: React.FC<Props> = ({ lots }) => {
  const { t, lang } = useLang();
  const { getUser } = useUsers();
  const [sortedLots, setSortedLots] = useState<iLot[]>([]);

  useEffect(() => {
    setSortedLots(retrieveNfts(lots));
  }, []);

  return (
    <Root>
      <div>
        <h2>{t('Home:intro:title')}</h2>
        <p>{t('Home:intro:desc:1')}</p>
        <p>{t('Home:intro:desc:2')}</p>
        <Link href={'/collections'}>
          <Button variant="secondary" size="m" iconRight={<RiArrowRightLine />}>
            {t('Home:show_all_collections:short')}
          </Button>
        </Link>
      </div>
      <CardContainer>
        {sortedLots.map((lot) => {
          const statics = generateStatics(lot?.nft, StaticsSizes.THUMB);
          return (
            <Link href={`/nft/${lot?.lot_id}`} key={lot?.nft?.id}>
              <CardItem background={statics.primary}>
                <CardContent>
                  <p>{getUser(lot?.nft?.creator).name}</p>
                  <h3>{JSON.parse(lot?.nft?.title)[lang]}</h3>
                </CardContent>
              </CardItem>
            </Link>
          );
        })}
      </CardContainer>
    </Root>
  );
};

export default IntroductionComponent;

const Root = styled.div`
  ${() => css`
    margin-block: 120px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div:is(:first-of-type) {
      @media screen and (min-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-width: 640px;
        text-align: center;
      }
      > h2 {
        max-width: 540px;
        ${typo.displayS}

        @media screen and (min-width: 600px) {
          ${typo.displayM}
        }

        @media screen and (min-width: 1440px) {
          ${typo.displayL}
        }
      }
      > p {
        ${typo.body_M}

        @media screen and (min-width: 768px) {
          ${typo.body_L}
        }
      }
      button,
      p {
        margin-top: 24px;
      }
    }
  `}
`;

const CardContainer = styled.div`
  ${({ theme }) => css`
    overflow-x: hidden;
    min-height: 300px;
    margin-top: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 900px;

    div {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      &:nth-child(1) {
        display: none;

        @media screen and (min-width: 600px) {
          transform: translate(-70%, -50%);
          display: block;
          width: 25%;
          left: 18%;
        }
      }

      &:nth-child(2) {
        z-index: 1;
        left: 18%;
        width: 30%;

        @media screen and (min-width: 600px) {
          left: 28%;
          width: 28%;
        }
      }

      &:nth-child(3) {
        z-index: 2;
        min-width: 200px;
        width: 40%;
        flex-grow: 1;
      }

      &:nth-child(4) {
        z-index: 1;
        width: 30%;
        transform: translate(50%, -50%);

        @media screen and (min-width: 600px) {
          transform: translate(30%, -50%);
          width: 28%;
        }
      }

      &:nth-child(5) {
        display: none;

        @media screen and (min-width: 600px) {
          transform: translate(98%, -50%);
          display: block;
          width: 25%;
        }
      }
    }
  `}
`;

declare interface CardProps {
  background: string;
}

const CardItem = styled.div<CardProps>`
  ${({ background }) => css`
    aspect-ratio: 1/1;
    min-width: 120px;
    max-width: 280px;
    background: url(${background}) center center no-repeat;
    background-size: cover;
    border-radius: 16px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    position: relative;

    span:is(:first-of-type) {
      opacity: 0;
      position: absolute;
      bottom: 20px;
      left: 20px;
      transform: translateY(5px);
      transition: all 0.3s ease-in-out;
      max-width: 83%;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.8) 89.58%);
      opacity: 1;
      box-shadow: inset 0px 0px 0px 1px rgb(255 255 255 / 5%);
      border-radius: 16px;
    }

    &:hover {
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      z-index: 5 !important;
      &::after {
        background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.7) 89.58%);
        box-shadow: inset 0px 0px 0px 1px rgb(255 255 255 / 10%);
      }

      span:is(:first-of-type) {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.3s ease-in-out;
      }
    }
  `}
`;

const CardContent = styled.span`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 8px;
    z-index: 1;

    p {
      ${typo.uiText_M_Bold}
    }
    h3 {
      ${typo.uiText_XL_Bold}
    }
  `}
`;
