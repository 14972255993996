import React, { useEffect, useState } from 'react';
import { RiArrowRightLine } from 'react-icons/ri';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from '~/components/_ui-kit/Button';
import { useLang } from '~/store/languageContext/hooks';
import { useViewport } from '~/store/viewportContext/viewportContext';
import typo from '~/styles/typography';
import Link from 'next/link';
import { useStore } from '~/store/store';
import router from 'next/router';

declare interface Props {
  artists: any;
}

const retrieveSlidesPerView = (width) => {
  if (width <= 599) return 2.5;
  if (width <= 767 && width >= 600) return 3.5;
  if (width <= 1023 && width >= 768) return 4.8;
  if (width >= 1024) return 6;
  return 6;
};

const ArtistsSlider: React.FC<Props> = ({ artists }) => {
  const { t } = useLang();
  const { width } = useViewport();
  const [slidesPerView, setSlidesPerView] = useState(retrieveSlidesPerView(width) || 6);
  const { actions } = useStore();

  useEffect(() => {
    setSlidesPerView(retrieveSlidesPerView(width));
  }, [width]);

  const handleArtistNavigation = () => {
    actions.setMarketplaceLastTab('3');
    router.push('/marketplace');
  };

  return (
    <Root>
      <ArtistsIntro>
        <h5>{t('Artists:slider:title')}</h5>
        <p>{t('Artists:slider:desc')}</p>
        <Button
          size="m"
          variant="secondary"
          iconRight={<RiArrowRightLine />}
          onClick={handleArtistNavigation}
        >
          {t('Artists:slider:button')}
        </Button>
      </ArtistsIntro>
      <InnerContainer>
        <Swiper
          slidesPerView={slidesPerView}
          slidesPerGroup={1}
          spaceBetween={16}
          slidesOffsetAfter={width < 1024 ? 66 : 0}
        >
          {artists?.map((art) => {
            return (
              <SwiperSlide>
                <Link href={`/user/${art[0]}`}>
                  <ArtistCard background={art[1].avatar}>
                    {/* <img src={art[1].avatar} alt={art[1].name} /> */}
                    <div>
                      <h3>{art[1].name}</h3>
                      {/* <p>ilustradora</p> */}
                    </div>
                  </ArtistCard>
                </Link>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </InnerContainer>
    </Root>
  );
};

export default ArtistsSlider;

const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-block: 120px 40px;

    @media only screen and (min-width: 599px) {
      margin-block: 120px 60px;
    }

    @media only screen and (min-width: 767px) {
      margin-block: 120px 80px;
    }
  `}
`;

declare interface InnerContainerProps {
  isReachEnd?: boolean;
}

const InnerContainer = styled.div<InnerContainerProps>`
  ${({ theme, isReachEnd }) => css`
    @media only screen and (max-width: 1023px) {
      width: 100vw;
      position: relative;
    }
  `}
`;

declare interface CardProps {
  background: string;
}

const ArtistCard = styled.div<CardProps>`
  ${({ theme, background }) => css`
    cursor: pointer;
    height: 280px;
    border-radius: 16px;
    padding: 16px 16px 20px 16px;
    background: url(${background}) no-repeat center center;
    background-size: cover;
    overflow: hidden;
    display: flex;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      border-radius: 0;
      background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 1) 89.58%);
      transparency: 0.7;
    }

    &:hover {
      > img,
      > div {
        opacity: 1;
        transform: translateY(0px);
        transition: all 0.3s ease-in-out;
      }
      &::after {
        border-radius: 16px;
        box-shadow: inset 0px 0px 0px 1px rgb(255 255 255 / 10%);
        background: linear-gradient(180deg, rgba(18, 18, 18, 0) 0%, rgba(18, 18, 18, 0.7) 89.58%);
      }
    }

    > img,
    > div {
      opacity: 0;
      transform: translateY(-5px);
      transition: all 0.3s ease-in-out;
    }

    > img {
      position: absolute;
      top: 16px;
      right: 16px;
      border-radius: 50%;
      max-width: 32px;
      aspect-ratio: 1/1;
    }

    > div {
      z-index: 2;
      max-width: 100%;
      word-wrap: break-word;
      margin-top: auto;

      > h3 {
        ${typo.uiText_XL_Bold};
        margin-top: auto;
      }
      > p {
        margin-top: 8px;
        ${typo.overline_uppercase}
      }
    }
  `}
`;

const ArtistsIntro = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 64px;

    h5 {
      ${typo.heading2}
    }
    p {
      ${typo.body_L}
      @media screen and (min-width: 1024px) {
        max-width: 600px;
      }
    }
  `}
`;
