// React - NEXT
import { useEffect, useState } from 'react';
// Components
import { Swiper, SwiperSlide } from 'swiper/react';
import Tabs, { Tab } from '~/components/_ui-kit/Tabs';
// Styles
import styled, { css } from 'styled-components';
import typo from '~/styles/typography';
import 'swiper/css';
// Hooks and interfaces
import { iLot } from '~/store/services/mito/mito.models';
import { useLang } from '~/store/languageContext/hooks';
import Button from '~/components/_ui-kit/Button';
import useMediaDevice from '~/hooks/useMediaDevice';
import { useViewport } from '~/store/viewportContext/viewportContext';
import NFTCard from '~/components/Cards/NFTCard/NFTCard';
import { useStore } from '~/store/store';
import router from 'next/router';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { RiArrowRightLine } from 'react-icons/ri';

declare interface Props {
  lots: iLot[];
}

const retrieveSlidesPerView = (width) => {
  if (width <= 599) return 1.3;
  if (width <= 767 && width >= 600) return 2;
  if (width <= 1023 && width >= 768) return 2.5;
  if (width <= 1439 && width >= 1024) return 3.5;
  if (width >= 1440) return 4;
  return 4;
};

const retrieveSlidesPerGroup = (width) => {
  if (width < 600 || (width < 768 && width > 600)) return 1;
  return 4;
};

const ArtworksSlider = ({ lots }: Props) => {
  const [activeKey, setActiveKey] = useState('1');

  const [firstTabIndex, setFirstTabIndex] = useState(0);
  const [secondTabIndex, setSecondTabIndex] = useState(0);
  const [thirdTabIndex, setThirdTabIndex] = useState(0);

  const [swiper_one, setSwiper_one] = useState(null);
  const [swiper_two, setSwiper_two] = useState(null);
  const [swiper_three, setSwiper_three] = useState(null);

  const updateTab = (key: string) => setActiveKey(key);

  const { t } = useLang();
  const { width } = useViewport();
  const isTabletS = useMediaDevice('tabletS');
  const { actions } = useStore();

  const [slidesPerView, setSlidesPerView] = useState(retrieveSlidesPerView(width) || 4);
  const [slidesPerGroup, setSlidesPerGroup] = useState(retrieveSlidesPerGroup(width));

  const handleNavigation = () => {
    actions.setMarketplaceLastTab('1');
    router.push('/marketplace');
  };

  useEffect(() => {
    setSlidesPerView(retrieveSlidesPerView(width));
    setSlidesPerGroup(retrieveSlidesPerGroup(width));
  }, [width]);

  const SliderButtons = () => {
    switch (activeKey) {
      case '1':
        return (
          <>
            <Button
              size="m"
              variant="secondary"
              disabled={firstTabIndex === 0}
              onClick={() => swiper_one.slidePrev()}
              iconRight={<FiChevronLeft />}
            />
            <Button
              size="m"
              variant="secondary"
              disabled={swiper_one?.isEnd || false}
              onClick={() => swiper_one.slideNext()}
              iconRight={<FiChevronRight />}
            />
          </>
        );
      case '2':
        return (
          <>
            <Button
              size="m"
              variant="secondary"
              disabled={secondTabIndex === 0}
              onClick={() => swiper_two.slidePrev()}
              iconRight={<FiChevronLeft />}
            />
            <Button
              size="m"
              variant="secondary"
              disabled={swiper_two?.isEnd || false}
              onClick={() => swiper_two.slideNext()}
              iconRight={<FiChevronRight />}
            />
          </>
        );
      case '3':
        return (
          <>
            <Button
              size="m"
              variant="secondary"
              disabled={thirdTabIndex === 0}
              onClick={() => swiper_three.slidePrev()}
              iconRight={<FiChevronLeft />}
            />
            <Button
              size="m"
              variant="secondary"
              disabled={swiper_three?.isEnd || false}
              onClick={() => swiper_three.slideNext()}
              iconRight={<FiChevronRight />}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Root>
      <LinkContainer>
        <h5>{t('Artworks:slider:title')}</h5>
        {isTabletS && (
          <Button
            size="m"
            variant="secondary"
            iconRight={<RiArrowRightLine />}
            onClick={handleNavigation}
          >
            {t('Home:show_all_artworks:full')}
          </Button>
        )}
      </LinkContainer>
      {/* <Tabs onChange={updateTab} activeKey={activeKey}> */}
      {/* <Tab tab={t('Collection:slider:new')} key="1"> */}
      <InnerContainer isReachEnd={swiper_one?.isEnd}>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={16}
          onSlideChange={(e) => setFirstTabIndex(e.activeIndex)}
          onInit={(swiper) => setSwiper_one(swiper)}
          slidesPerGroup={slidesPerGroup}
        >
          {lots?.slice(0, 12).map((lot) => {
            return (
              <SwiperSlide
                style={{
                  maxWidth: width <= 1440 ? '280px' : 'none',
                  width: width <= 1440 ? '100%' : 'auto',
                }}
                key={lot.id}
              >
                <NFTCard lot={lot} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </InnerContainer>
      {/* </Tab>
        <Tab tab={t('Artworks:slider:visited')} key="2">
          <InnerContainer isReachEnd={swiper_two?.isEnd}>
            <Swiper
              slidesPerView={slidesPerView}
              spaceBetween={16}
              onSlideChange={(e) => setSecondTabIndex(e.activeIndex)}
              onInit={(swiper) => setSwiper_two(swiper)}
              slidesPerGroup={slidesPerGroup}
            >
              {lots?.slice(0, 12).map((lot) => {
                return (
                  <SwiperSlide key={lot.id}>
                    <NFTCard lot={lot} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </InnerContainer>
        </Tab>
        <Tab tab={t('Artworks:slider:rewards')} key="3">
          <InnerContainer isReachEnd={swiper_three?.isEnd}>
            <Swiper
              slidesPerView={slidesPerView}
              spaceBetween={16}
              onSlideChange={(e) => setThirdTabIndex(e.activeIndex)}
              onInit={(swiper) => setSwiper_three(swiper)}
              slidesPerGroup={slidesPerGroup}
            >
              {lots?.slice(0, 12).map((lot) => {
                return (
                  <SwiperSlide key={lot.id}>
                    <NFTCard lot={lot} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </InnerContainer>
        </Tab>
      </Tabs> */}
      {!isTabletS && (
        <LinkContainer>
          <Button
            size="m"
            variant="secondary"
            iconRight={<RiArrowRightLine />}
            onClick={handleNavigation}
          >
            {t('Home:show_all_artworks')}
          </Button>
        </LinkContainer>
      )}
      {isTabletS && (
        <SwiperButtons>
          <SliderButtons />
        </SwiperButtons>
      )}
    </Root>
  );
};

export default ArtworksSlider;

const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin: 120px 0 0 0;

    h5 {
      ${typo.heading2}
    }
  `}
`;

declare interface InnerContainerProps {
  isReachEnd?: boolean;
}

const InnerContainer = styled.div<InnerContainerProps>`
  ${({ theme, isReachEnd }) => css`
    padding-bottom: 32px;

    @media screen and (max-width: 1439px) {
      width: 100vw;
      position: relative;
      right: ${isReachEnd ? '56px' : '0'};
    }
  `}
`;

const CustomNFTCard = styled(NFTCard)`
  ${({ theme }) => css`
    width: 100%;
  `}
`;

const LinkContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  `}
`;

const SwiperButtons = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  `}
`;
