import dynamic from 'next/dynamic';
import { GetServerSidePropsContext } from 'next';
import { Banner } from '~/components/Banners';
// import { CollectionCard } from '~/components/Cards';
import { useCms } from '~/store/cmsContext/hooks';
import classes from '~/styles/Home.module.scss';
import { MAX_AGE_CACHE } from '~/constants';
const BannerFeedComponent = dynamic(() => import('~/components/_cdk/BannerFeed'));
const Layout = dynamic(() => import('~/components/Layout/Layout'));

// New endpoints
import MitoServiceNew from '~/store/services/mito/mito.service';
import { iBlogPost, iCollection, iLot } from '~/store/services/mito/mito.models';
import _orderBy from 'lodash/orderBy';
import CollectionsSlider from '~/components/_cdk/CollectionsSlider/CollectionsSlider';
import ArtworksSlider from '~/components/_cdk/ArtworksSlider/ArtworksSlider';
import EditorialSection from '~/components/_cdk/EditorialSection/EditorialSection';
import ArtistsSlider from '~/components/_cdk/ArtistsSlider/ArtistsSlider';
import IntroductionComponent from '~/components/_cdk/IntroductionComponent/IntroductionComponent';

interface Props {
  collections: iCollection[];
  lots: iLot[];
  blogPosts?: iBlogPost[];
}

export default function Home({ lots, collections, blogPosts }: Props) {
  const { cms } = useCms();
  const HeroBanner = (cms?.banners?.home?.hero || [])[0];
  const artists = cms.profiles
    ? Object.entries(cms?.profiles)
        .sort(() => 0.5 - Math.random())
        .slice(0, 6)
    : [];

  return (
    <>
      <Layout>
        <div className={classes.column}>
          <Banner banner={HeroBanner} />

          <BannerFeedComponent />

          <IntroductionComponent lots={lots} />

          <CollectionsSlider collections={collections} />

          <ArtworksSlider lots={lots} />

          {/* <EditorialSection blogPosts={blogPosts} /> */}

          <ArtistsSlider artists={artists} />
        </div>
      </Layout>
    </>
  );
}

export async function getServerSideProps(appContext: GetServerSidePropsContext) {
  appContext.res.setHeader(
    'Cache-Control',
    `public, s-maxage=${MAX_AGE_CACHE}, stale-while-revalidate=59`,
  );

  let lots: iLot[];
  let collections: iCollection[];
  let blogPosts: iBlogPost[];
  try {
    const [activeLots, activeCollections] = await Promise.all([
      // const [activeLots, activeCollections, activePosts] = await Promise.all([
      MitoServiceNew.getAllActiveLots(),
      MitoServiceNew.getAllCollections(),
      // MitoServiceNew.getBlogPosts(),
    ]);

    collections = activeCollections.data as unknown as iCollection[];
    collections = _orderBy(collections, (collection) => Number(collection?.createdAt), 'desc');
    lots = activeLots.data;
    lots = _orderBy(lots, (lot) => Number(lot?.block_timestamp_creation), 'desc');
    // blogPosts = activePosts.data;
  } catch (err) {}

  return {
    props: {
      lots: lots || null,
      collections: collections || null,
      // blogPosts: blogPosts || null,
    },
  };
}
