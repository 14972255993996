// React - NEXT
import { useEffect, useState } from 'react';
// Components
import { Swiper, SwiperSlide } from 'swiper/react';
import Tabs, { Tab } from '~/components/_ui-kit/Tabs';
import { CollectionCardN } from '~/components/Cards';
// Styles
import styled, { css } from 'styled-components';
import typo from '~/styles/typography';
import 'swiper/css';
// Hooks and interfaces
import { iCollection } from '~/store/services/mito/mito.models';
import { useLang } from '~/store/languageContext/hooks';
import Button from '~/components/_ui-kit/Button';
import useMediaDevice from '~/hooks/useMediaDevice';
import { useViewport } from '~/store/viewportContext/viewportContext';
import { useStore } from '~/store/store';
import router from 'next/router';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { RiArrowRightLine } from 'react-icons/ri';

declare interface Props {
  collections: iCollection[];
}

const retrieveSlidesPerView = (width) => {
  if (width <= 599) return 1.5;
  if (width <= 767 && width >= 600) return 2.25;
  if (width <= 1023 && width >= 768) return 2.8;
  if (width <= 1439 && width >= 1024) return 3.9;
  if (width >= 1440) return 5;
  return 5;
};

const retrieveSlidesPerGroup = (width) => {
  if (width < 600 || (width < 768 && width > 600)) return 1;
  return 5;
};

const CollectionsSlider = ({ collections }: Props) => {
  const [activeKey, setActiveKey] = useState('1');

  const [firstTabIndex, setFirstTabIndex] = useState(0);
  const [secondTabIndex, setSecondTabIndex] = useState(0);
  const [thirdTabIndex, setThirdTabIndex] = useState(0);

  const [swiper_one, setSwiper_one] = useState(null);
  const [swiper_two, setSwiper_two] = useState(null);
  const [swiper_three, setSwiper_three] = useState(null);

  const updateTab = (key: string) => setActiveKey(key);

  const { t } = useLang();
  const { width } = useViewport();
  const isTabletS = useMediaDevice('tabletS');
  const { actions } = useStore();

  const [slidesPerView, setSlidesPerView] = useState(retrieveSlidesPerView(width) || 5);
  const [slidesPerGroup, setSlidesPerGroup] = useState(retrieveSlidesPerGroup(width));

  const handleNavigation = () => {
    actions.setMarketplaceLastTab('2');
    router.push('/marketplace');
  };

  useEffect(() => {
    setSlidesPerView(retrieveSlidesPerView(width));
    setSlidesPerGroup(retrieveSlidesPerGroup(width));
  }, [width]);

  const SliderButtons = () => {
    switch (activeKey) {
      case '1':
        return (
          <>
            <Button
              size="m"
              variant="secondary"
              disabled={firstTabIndex === 0}
              onClick={() => swiper_one.slidePrev()}
              iconRight={<FiChevronLeft />}
            />
            <Button
              size="m"
              variant="secondary"
              disabled={swiper_one?.isEnd || false}
              onClick={() => swiper_one.slideNext()}
              iconRight={<FiChevronRight />}
            />
          </>
        );
      case '2':
        return (
          <>
            <Button
              variant="secondary"
              size="m"
              disabled={secondTabIndex === 0}
              onClick={() => swiper_two.slidePrev()}
              iconRight={<FiChevronLeft />}
            />
            <Button
              variant="secondary"
              size="m"
              disabled={swiper_two?.isEnd || false}
              onClick={() => swiper_two.slideNext()}
              iconRight={<FiChevronRight />}
            />
          </>
        );
      case '3':
        return (
          <>
            <Button
              variant="secondary"
              size="m"
              disabled={thirdTabIndex === 0}
              onClick={() => swiper_three.slidePrev()}
              iconRight={<FiChevronLeft />}
            />
            <Button
              variant="secondary"
              size="m"
              disabled={swiper_three?.isEnd || false}
              onClick={() => swiper_three.slideNext()}
              iconRight={<FiChevronRight />}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Root>
      <LinkContainer>
        <h5>{t('Home:mito_collections')}</h5>
        {isTabletS && (
          <Button
            size="m"
            variant="secondary"
            iconRight={<RiArrowRightLine />}
            onClick={handleNavigation}
          >
            {t('Home:show_all_collections:full')}
          </Button>
        )}
      </LinkContainer>
      {/* <Tabs onChange={updateTab} activeKey={activeKey}> */}
      {/* <Tab tab={t('Collection:slider:new')} key="1"> */}
      <InnerContainer isReachEnd={swiper_one?.isEnd}>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={16}
          onSlideChange={(e) => setFirstTabIndex(e.activeIndex)}
          onInit={(swiper) => setSwiper_one(swiper)}
          slidesPerGroup={slidesPerGroup}
        >
          {collections?.slice(0, 10).map((col) => {
            return (
              <SwiperSlide
                style={{
                  maxWidth: '262px',
                }}
                key={col.id}
              >
                <CustomCollectionCard nonMobile collection={col} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </InnerContainer>
      {/* </Tab>
        <Tab tab={t('Collection:slider:visited')} key="2">
          <InnerContainer isReachEnd={swiper_two?.isEnd}>
            <Swiper
              slidesPerView={slidesPerView}
              spaceBetween={16}
              onSlideChange={(e) => setSecondTabIndex(e.activeIndex)}
              onInit={(swiper) => setSwiper_two(swiper)}
              slidesPerGroup={slidesPerGroup}
            >
              {collections?.slice(0, 10).map((col) => {
                return (
                  <SwiperSlide key={col.id}>
                    <CustomCollectionCard nonMobile collection={col} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </InnerContainer>
        </Tab>
        <Tab tab={t('Collection:slider:sold')} key="3">
          <InnerContainer isReachEnd={swiper_three?.isEnd}>
            <Swiper
              slidesPerView={slidesPerView}
              spaceBetween={16}
              onSlideChange={(e) => setThirdTabIndex(e.activeIndex)}
              onInit={(swiper) => setSwiper_three(swiper)}
              slidesPerGroup={slidesPerGroup}
            >
              {collections?.slice(0, 10).map((col) => {
                return (
                  <SwiperSlide key={col.id}>
                    <CustomCollectionCard nonMobile collection={col} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </InnerContainer>
        </Tab>
      </Tabs> */}
      {!isTabletS && (
        <LinkContainer>
          <Button
            size="m"
            variant="secondary"
            iconRight={<RiArrowRightLine />}
            onClick={handleNavigation}
          >
            {t('Home:show_all_collections')}
          </Button>
        </LinkContainer>
      )}
      {isTabletS && (
        <SwiperButtons>
          <SliderButtons />
        </SwiperButtons>
      )}
    </Root>
  );
};

export default CollectionsSlider;

const Root = styled.div`
  ${({ theme }) => css`
    width: 100%;

    h5 {
      ${typo.heading2}
    }
  `}
`;

declare interface InnerContainerProps {
  isReachEnd?: boolean;
}

const InnerContainer = styled.div<InnerContainerProps>`
  ${({ theme, isReachEnd }) => css`
    padding-bottom: 32px;

    @media only screen and (max-width: 1439px) {
      width: 100vw;
      position: relative;
      right: ${isReachEnd ? '56px' : '0'};
    }
  `}
`;

const CustomCollectionCard = styled(CollectionCardN)`
  /* ${({ theme }) => css`
    max-width: 300px;
  `} */
`;

const LinkContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  `}
`;

const SwiperButtons = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
  `}
`;
